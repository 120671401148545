.newsletter {
    h2 {
        color: $green;
        text-align: center;
        padding-bottom: 16px;
        font-size: 30px;
        text-transform: uppercase;
        line-height: 38px;
        @media (min-width: 768px) {
            text-align: left;
            padding: 0 32px 0 0;
        }
    }
    .sailthru-form {
        font-family: "Open Sans", sans-serif;
        &__email {
            label {
                text-transform: capitalize;
                color: $dark-grey;
            }
        }
        &__dob {
            label {
                text-transform: capitalize;
                color: $dark-grey;
            }
            .select-style {
                border-radius: 8px;
            }
        }
        &__legal {
            font-family: "Open Sans", sans-serif;
            color: $dark-grey;
            a {
                &:hover {
                    color: $green;
                }
            }
        }
        &__restricted-age {
            font-family: "Open Sans", sans-serif;
            p {
                color: $dark-grey;
            }
        }
    }
}