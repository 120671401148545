.books {
    .container {
        @media (min-width: 768px) and (max-width: 1023px) {
            max-width: none;
        }
    }
    .component__text {
        h1 {
            margin-bottom: 0;
            font-size: 30px;
            line-height: 38px;
            @media (min-width: 768px) {
                font-size: 50px;
                line-height: 58px;
            }
        }
        &.grid-subtitle {
            margin: 0 auto;
            max-width: 600px;
        }
    }
    .book-landing {
        .book-list {
            @media (min-width: 1024px) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
        .pagination-top, .pagination-bottom {
            .pagination {
                li {
                    margin-bottom: 16px;
                }
            }
        }
        .book-card {
            @media (max-width: 767px) {
                padding: 10px 4px;
            }
            ul {
                min-height: 130px;
            }
            .book-title {
                padding: 16px 0 8px;
                margin-bottom: 0;
                a {
                    color: $dark-grey;
                    line-height: 27px;
                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                }
            }
            .book-price {
                font-size: 22px;
            }
        }
    }
}
