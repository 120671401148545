.main-navigation {
    background-color: $nav-background-colour;
    padding: 12px 8px;
    @media (min-width: 768px) {
        padding: 0 8px;
    }
    &.show-menu {
        padding-bottom: 32px;
        background: $dark-orange;
        .js-nav-hamburger {
            background: $nav-background-colour;
            margin: -12px -8px -8px;
            padding: 12px 8px;
        }
        @media (min-width: 768px) {
            padding: 0;
        }
    }
    .main-menu {
        @media (max-width: 767px) {
            margin: 32px 0 16px;
        }
        li {
            margin: 0;
            a {
                height: 100%;
                display: block;
                padding: 16px;
                color: $nav-links-colour;
                @extend %primary-font;
                font-size: 30px;
                line-height: 26px;
                text-transform: capitalize;
                @media (min-width: 1024px) {
                    padding: 16px 24px;
                }
                @media (max-width: 767px) {
                    margin-bottom: 8px;
                }
                &:hover {
                    background-color: $nav-links-background-colour-hover;
                    color: $nav-links-colour-hover;
                    @media (max-width: 767px) {
                        background-color: $dark-orange;
                    }
                }
                &.is-active {
                    background-color: $nav-current-link-background-colour;
                    color: $nav-links-colour-hover;
                    @media (max-width: 767px) {
                        background-color: $dark-orange;
                    }
                }
            }
        }
    }
    .hamburger-box {
        .hamburger-inner, ::after, ::before {
            background-color: $dark-orange !important;
        }
    }
}