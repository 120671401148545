.activity-detail {
    .asset-detail {
        align-items: flex-start;
        &__image {
            img {
                max-width: 90%;
                margin: 0 auto;
                @media (max-width: 767px) {
                    max-width: 75%;
                }
            }
        }
        &__info {
            justify-content: flex-start;
        }
        &__category {
            color: $orange;
            font-size: 20px;
            margin: 8px 0 0;
            @media (max-width: 767px) {
                text-align: center;
            }
        }
        &__title {
            color: $red;
            text-transform: capitalize;
            margin-bottom: 16px;
            font-size: $h2-font-size;
            @media (max-width: 767px) {
                text-align: center;
                font-size: $h2-font-size-mobile;
                line-height: 1.2;
                margin-top: 8px;
            }
        }
        &__description {
            margin-bottom: 32px;
            font-size: 20px;
            @media (max-width: 767px) {
                text-align: center;
                padding: 0 16px;
            }
        }
        &__action {
            @include siteButton($red, $white);
            font-size: 24px;
            padding: 16px 42px;
            line-height: 26px;
            text-transform: uppercase;
            border-radius: 8px;
            @media (max-width: 767px) {
                display: flex;
                width: 50%;
                justify-content: center;
                font-size: 18px;
                margin: 0 auto;
            }
            &:hover {
                background-color: $red;
                filter: brightness(1.2);
                color: $white;
            }
        }
        &__carousel {
            @include full_width_colour($white);
            &:after {
                z-index: 1;
            }
        }
        &__related-title {
            color: $red;
            text-transform: capitalize;
            font-size: $h4-font-size;
            padding: 16px 0;
        }
        &__related-subtitle {
            display: none;
        }
        .related-book {
            margin-top: 64px;
            @media (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
            }
            h4 {
                color: $red;
                text-transform: none;
                line-height: 38px;
                font-size: 30px;
                margin-bottom: 4px;
                @media (max-width: 767px) {
                    flex-direction: column;
                    justify-content: center;
                    font-size: 26px;
                    line-height: 32px;
                }
            }
            &__price {
                display: none;
            }
            &__content {
                @media (max-width: 767px) {
                    width: 100%;
                    padding: 16px;
                    text-align: center;
                    margin-top: 16px;
                }
            }
        }
    }
    .assets-carousel {
        display: flex;
        align-items: flex-end;
        &__book-title {
            display: none
        }
    }
}