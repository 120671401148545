@font-face {
    font-family: "Clearface";
    src: url('../fonts/clearface-t.woff') format('opentype'), url("../fonts/clearface-t.woff2") format("woff2"), url("../fonts/clearface-t.woff") format("woff"), url("../fonts/clearface-t.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

%primary-font {
    font-family: "Clearface";
    -webkit-font-smoothing: antialiased;
}

%main-font {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-size: $base-font-size;
    line-height: $base-font-size + 9;
    text-transform: none;
    @media (max-width: 767px) {
        font-size: 15px;
        line-height: 22px;
    }
}

h1,
.primary-font {
    @extend %primary-font;
}

.primary-text-colour {
    color: $primary-color;
}

h2,
h3,
.secondary-font {
    @extend %primary-font;
}

.secondary-text-colour {
    color: $secondary-color;
}

h4,
h5,
body {
    @extend %primary-font;
}

.main-font {
    @extend %main-font;
}

.main-text-colour {
    color: $body-font-color;
}

.white-text-colour {
    color: $white;
}

.green-text-colour {
    color: $green;
}

.orange-text-colour {
    color: $orange;
}

.dark-orange-text-colour {
    color: $dark-orange;
}

.yellow-text-colour {
    color: $yellow;
}

.blue-text-colour {
    color: $blue;
}

.red-text-colour {
    color: $red;
}

.rose-text-colour {
    color: $rose;
}

h1 {
    font-size: $h1-font-size;
    line-height: $h1-font-size + 5;
    margin-bottom: 16px;
    @media (max-width: 767px) {
        font-size: $h1-font-size-mobile;
        line-height: 36px;
        margin-bottom: 8px;
    }
}

h2 {
    font-size: $h2-font-size;
    line-height: $h2-font-size + 5;
    margin-bottom: 8px;
    @media (max-width: 767px) {
        font-size: $h2-font-size-mobile;
    }
}

h3 {
    font-size: $h3-font-size;
    line-height: $h3-font-size + 5;
    margin-bottom: 8px;
    @media (max-width: 767px) {
        font-size: $h3-font-size-mobile;
    }
}

h4 {
    font-size: $h4-font-size;
    line-height: $h4-font-size + 5;
    margin-bottom: 8px;
    @media (max-width: 767px) {
        font-size: $h4-font-size-mobile;
        line-height: 36px;
    }
}

h5 {
    font-size: $h5-font-size;
    line-height: $h5-font-size + 5;
    margin-bottom: 8px;
    @media (max-width: 767px) {
        font-size: $h5-font-size-mobile;
    }
}

blockquote {
    font-size: 30px;
    line-height: 48px;
    @media (max-width: 767px) {
        font-size: 20px;
        line-height: 27px;
    }
    cite {
        font-style: normal;
        text-transform: uppercase;
        margin-top: 32px;
        display: block;
        font-size: 20px;
        line-height: 22px;
        @media (max-width: 767px) {
            margin-top: 16px;
        }
    }
}

.pagination {
    li {
        a {
            color: $red;
        }
    }
}

.separator {
    padding-top: 32px;
    border-top: 3px solid $section-border-top;
}

a {
    color: $body-font-color;
    &:hover {
        color: $secondary-color;
        cursor: pointer;
    }
}

p {
    color: $body-font-color;
    margin-bottom: 8px;
    font-size: $base-font-size;
    line-height: $base-font-size + 8;
    @media (max-width: 767px) {
        font-size: $base-font-size-mobile;
        line-height: $base-font-size-mobile + 8;
    }
}

.quiz-button {
    @extend %secondary-font;
    @include siteButton($secondary-color, $white);
}

.primary-button {
    @extend %primary-font;
    @include siteButton($red, $white);
    font-size: 24px;
    padding: 16px 42px;
    line-height: 26px;
    text-transform: uppercase;
    border-radius: 8px;
    @media (max-width: 767px) {
        font-size: 18px;
    }
    &:hover {
        background-color: $red;
        filter: brightness(1.2);
        color: $white;
    }
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.secondary-button {
    @extend %primary-font;
    @include siteButton($green, $white);
    font-size: 24px;
    padding: 16px 42px;
    line-height: 26px;
    text-transform: uppercase;
    border-radius: 8px;
    @media (max-width: 767px) {
        font-size: 18px;
    }
    &:hover {
        background-color: $green;
        filter: brightness(1.2);
        color: $white;
    }
    &__character {
        display: inline-block;
        text-align: center;
    }
}

.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    margin: 20px 0 48px 35px;
    a {
        @extend %primary-font;
        text-transform: capitalize;
        position: relative;
        font-size: 20px;
        color: $red;
        &::before{
            content: '<';
            position: absolute;
            display: block;
            top: 50%;
            left: -25px;
            height: 23px;
            width: 23px;
            transform: translateY(-60%);
            @media (max-width: 767px) {
                height: 18px;
                width: 18px;
                transform: translateY(-80%);
            }
        }
    }
}
