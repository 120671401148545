.footer {
    font-family: "Open Sans", sans-serif;
    &-container {
        padding: 32px 16px;
    }
    &__nav, p {
        margin-bottom: 1em !important;
    }
    &__nav, &__copyright, p {
        font-size: 12px;
        @media (min-width: 768px) {
            font-size: 15px;
        }
    }
}
