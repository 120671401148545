@import "variables";
@import "typography";

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.red-background {
    background-color: $red;
    @include full_width_colour($red);
}

.yellow-background {
    background-color: $yellow;
    @include full_width_colour($yellow);
}

.pale-yellow-background {
    background-color: $pale-yellow;
    @include full_width_colour($pale-yellow);
}

.rose-background {
    background-color: $rose;
    @include full_width_colour($rose);
}

.pale-green-background {
    background-color: $pale-green;
    @include full_width_colour($pale-green);
}

.sky-blue-background {
    background-color: $sky-blue;
    @include full_width_colour($sky-blue);
}

.blue-background { 
    background-color: $blue; 
    @include full_width_colour($blue);
}