.author-illustrator {
    main {
        h4 {
            color: $red;
            text-align: center;
            font-size: 30px;
            line-height: 38px;
            @media (min-width: 768px) {
                text-align: left;
                margin-bottom: 16px;
            }
        }
        p {
            margin-bottom: 32px;
        }
        .author-bio {
            a {
                text-decoration: underline;
                text-underline-offset: 2px;
                font-weight: bold;
            }
        }
    }
}