.supafolio-loader-container {
    .supafolio-loader {
        border-top-color: $primary-color;
    }
}
.book-detail {
    @media (max-width: 767px) {
        max-width: none !important;
    }
    .book-content {
        img {
            @media (max-width: 767px) {
                max-width: 75%;
            }
        }
        .md\:w-3\/5 {
            @media (min-width: 768px) {
                padding-left: 64px;
            }
        }
    }
    .button-asset {
        @extend %main-font;
        text-transform: uppercase;
        background: $orange;
        font-weight: normal;
        display: flex;
        justify-content: center;
        margin: 16px 0;
        padding: 8px 32px;
        font-size: 19px;
        line-height: 31px;
        &.aerio_preview {
            background: transparent;
            padding: 0;
            a {
                text-transform: uppercase;
                background: $orange;
                font-weight: 400;
                width: 100%;
                border-radius: 9999px;
                padding: 8px 32px;
                font-size: 19px;
                line-height: 31px;

                &:hover {
                    color: $orange;
                    background: $white;
                }
                @media (max-width: 767px) {
                    font-size: 20px;
                }
            }
        }
        &--audio {
            box-shadow: none;
            background: $white;
            &::before {
                margin-right: 16px;
            }
        }
        @media (max-width: 767px) {
            margin: 32px auto;
            font-size: 20px;
        }
        a {
            color: white;
        }
    }
    .book-title {
        color: $red;
        margin-bottom: 0;
        font-size: 30px;
        line-height: 38px;
        @media (min-width: 768px) {
            font-size: 50px;
            line-height: 58px;
        }
    }
    .book-contributors {
        font-family: "Open Sans", sans-serif;
        color: $dark-grey;
        margin: 24px 0 32px;
        letter-spacing: 1px;
        font-size: 18px;
        line-height: 26px;
        @media (min-width: 768px) {
            font-size: 20px;
            line-height: 28px;
        }
    }
    .flextabs-container {
        @media (max-width: 767px) {
            width: auto;
            margin: 0 -24px 32px;
        }
    }
    .flextabs {
        &__tab button {
            @extend %primary-font;
            color: $red;
            text-transform: capitalize;
            font-size: 30px;
            line-height: 38px;
            @media (min-width: 768px) and (max-width: 1279px) {
                font-size: 26px;
            }
        }
        @media (min-width: 768px) {
            &__content {
                border-top: 2px solid $dark-orange;
                font-size: 22px;
                line-height: 38px;
                padding-top: 64px;
                &::before,
                &::after {
                    border-top: 2px solid $dark-orange;
                    height: calc(100% + 2px);
                    margin-top: -1px;
                }
                p,
                li {
                    margin-bottom: 40px !important;
                    color: $dark-grey;
                }
                &.product-details {
                    li {
                        margin-bottom: 0 !important;
                    }
                }
            }
            &__toggle {
                padding: 12px 24px;
                &--active {
                    &--last {
                        border: 2px solid;
                        border-color: $dark-orange $dark-orange $white;
                        border-bottom: 0;
                        margin-bottom: -2px;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            margin: 16px -16px 0;
            &::before,
            &::after {
                content: none;
            }
            &__tab {
                position: relative;
                padding: 16px 24px;
                background: $red;
                color: $white;
                font-size: 28px;
                &:not(:last-of-type) {
                    margin-bottom: 8px;
                }
                button {
                    color: $white;
                }
            }
            &__toggle {
                &::after {
                    content: "+";
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 58px;
                }
                &--active {
                    &::after {
                        content: "-";
                        position: absolute;
                        right: 32px;
                        top: 44%;
                        transform: translateY(-44%);
                        font-size: 64px;
                    }
                }
            }
            &__content {
                background: $white;
                padding: 16px 24px;
                text-align: center;
                margin-top: -8px;
                p,
                li {
                    margin-bottom: 32px !important;
                }
                &.product-details {
                    text-align: start;
                    li {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
    .buy-button-wrapper {
        background: $background-buy-section;
        max-width: 350px;
        @media (max-width: 767px) {
            max-width: none;
            margin: 32px auto 24px;
            padding: 32px;
        }
        .retailers-wrapper {
            @extend %main-font;
            border: 1px solid $dark-grey;
            border-radius: 3px;
            color: $dark-grey;
            ul,
            button,
            li {
                border-radius: 3px;
            }
            .icon-play {
                &::before {
                    top: 50%;
                    transform: rotate(90deg) translateX(-50%);
                }
            }
        }
        .format {
            @extend %primary-font;
            text-transform: capitalize;
            color: $dark-grey;
            font-size: 30px;
            line-height: 38px;
            margin-bottom: 16px;
            @media (min-width: 768px) {
                margin-bottom: 32px;
            }
        }
        .price {
            @extend %main-font;
            font-size: 24px;
            line-height: 38px;
            color: $dark-grey;
            letter-spacing: 1.2px;
        }
        .buy-button {
            @include siteButton($red, $white);
            font-size: 24px;
            padding: 16px 42px;
            line-height: 26px;
            text-transform: uppercase;
            border-radius: 8px;
            @media (max-width: 767px) {
                font-size: 18px;
            }
            &:hover {
                background-color: $red;
                filter: brightness(1.2);
                color: $white;
            }
        }
    }
    .other-formats {
        text-transform: capitalize;
        p {
            @extend %primary-font;
            font-size: 30px;
            line-height: 38px;
            color: $dark-grey;
            margin: 3px 0 16px;
            &::after {
                content: ":";
            }
        }
        li {
            margin-bottom: 8px;
        }
        a {
            color: $red;
            font-size: 20px;
            line-height: 28px;
        }
    }
    .related-books {
        img {
            max-width: 100%;
        }
        &-title {
            padding: 16px 8px;
            @media (min-width: 768px) {
                border-top: 2px solid $yellow;
                padding-top: 48px;
            }
            h1 {
                font-size: 30px;
                line-height: 38px;
                color: $red;
            }
        }
    }
    .books-carousel {
        @media (min-width: 768px) {
            padding: 0 16px;
        }
        .books-container {
            align-items: center;
        }
    }
}
