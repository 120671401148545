.home {
  .main-hero {
    h1 {
      font-size: $h3-font-size-mobile;
      line-height: 34px;
      text-align: center;
      @include breakpoint(large-tablet) {
        font-size: $h3-font-size;
        line-height: 53px;
      }
      @media (min-width: 1023px) {
        text-align: left;
      }
    }
    h2 {
      margin-bottom: 48px;
      font-size: $h1-font-size-mobile;
      line-height: 36px;
      text-transform: none;
      text-align: center;
      @include breakpoint(large-tablet) {
        font-size: $h1-font-size;
        line-height: 53px;
      }
      @media (min-width: 1023px) {
        text-align: left;
      }
    }
    .hero {
      padding-top: 32px;
      &__container {
        &--left {
          @media (max-width: 1023px) {
            &.md\:w-2\/5 {
              width: 100%;
            }
            .component {
              &__link {
                text-align: center;
              }
            }
          }
          @media (min-width: 1024px) {
            padding: 32px;
          }
        }
        &--right {
          @media (max-width: 1023px) {
            &.md\:w-3\/5 {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .secondary-hero {
    .hero {
      h1 {
        @media (max-width: 767px) {
          margin-top: 32px;
        }
      }
      h2 {
        font-size: 16px;
        line-height: 22px;
        text-transform: none;
        letter-spacing: 0;
        color: $body-font-color;
        margin: 0 0 32px;
        @include breakpoint(large-tablet) {
          margin: 16px 0 64px;
          font-size: 20px;
          line-height: 27px;
        }
      }
      &__container {
        &--left {
          order: 2;
          @media (min-width: 768px) {
            &.md\:w-2\/5 {
              padding-left: 64px;
              width: 60%;
            }
          }
          @media (min-width: 1024px) {
            padding-left: 80px;
          }
          .component {
            &__link {
              a {
                background-color: $rose;
              }
            }
          }
        }
        &--right {
          order: 1;
          @media (min-width: 768px) {
            &.md\:w-3\/5 {
              width: 40%;
            }
          }
        }
      }
    }
  }

  .home-blockquote {
    .site-carousel {
      align-items: center;
    }
    .tns-controls {
      button {
        opacity: 1;
      }
      [data-controls="prev"] {
        background-image: url("../img/arrow-white-prev.png");
      }
      [data-controls="next"] {
        background-image: url("../img/arrow-white-next.png");
      }
    }
  }
}