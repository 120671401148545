$dark-grey: #454545;
$red: #AA2D2C;
$white: #FFFFFF;
$pale-orange: #F3DEBB;
$dark-orange: #DF5C33;
$orange: #F8954D;
$black: #000000;
$blue: #70C2CA;
$sky-blue: #98D5EC;
$rose: #F06564;
$pale-green: #E9F9DB;
$green: #8AA231;
$dark-grey: #454545;
$pale-yellow: #FEF6D8;
$yellow: #F9CE5B;
$light-purple: #B4AFD7;

$max-width: 1060px;
$animation-time: 0.3s;
$section-border-top: $red;
$background-body: $white;
$body-font-color: $dark-grey;
$primary-color: $red;
$secondary-color: $dark-orange;
$background-buy-section: $pale-orange;

$assetBackgrounds: $blue, $yellow, $rose, $light-purple, $green, $sky-blue, $orange;

/* Header */

$header-background-color: $white;

/* NAVIGATION */

$nav-links-colour: $white;
$nav-links-colour-hover: $yellow;
$nav-links-background-colour-hover: $red;
$nav-current-link-colour: $yellow;
$nav-current-link-background-colour: $red;
$nav-background-colour: $red;

/* default font sizes */

$base-font-size: 20px;
$h1-font-size: 60px;
$h2-font-size: 50px;
$h3-font-size: 40px;
$h4-font-size: 30px;
$h5-font-size: 16px;

/* default font sizes mobile */
$base-font-size-mobile: 16px;
$h1-font-size-mobile: 35px;
$h2-font-size-mobile: 30px;
$h3-font-size-mobile: 27px;
$h4-font-size-mobile: 20px;
$h5-font-size-mobile: 16px;
