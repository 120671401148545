.character-nav {
    &__item {
        padding: 16px 0;
        .component {
            &__image {
                position: relative;
                z-index: 1;
                a {
                    transition: $animation-time all;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index: -1;
                        border-radius: 50%;
                        transition: all 0.3s;
                    }
                    &:hover, &:active {
                        &:after {
                            top: 7%;
                            left: 10%;
                            bottom: 12%;
                            right: 9%;
                            background-color: #98D5EC;
                            border-radius: 50%
                        }
                    }
                }
            }
            &__link {
                a {
                    @extend %primary-font;
                    font-size: 24px;
                    line-height: 32px;
                    border: 3px solid transparent;
                    border-radius: 10px;
                    padding: 8px 24px;
                    transition: $animation-time all;
                    margin-top: -12px;
                    @media (min-width: 768px) {
                        font-size: 27px;
                        line-height: 36px;
                    }
                    &:hover, &:active {
                        color: $black;
                        border-color: $sky-blue;
                    }
                }
            }
            }
            &.is-active {
                .component {
                    &__image {
                        a {
                            &:after {
                                top: 7%;
                                left: 10%;
                                bottom: 12%;
                                right: 9%;
                                background-color: #98D5EC;
                                border-radius: 50%
                            }
                        }
                    }
                    &__link{
                        a {
                            color: $black;
                            border-color: $sky-blue;
                        }
                    }
                } 
            }
            @include breakpoint(large-tablet) {
                &:first-of-type {
                    .component {
                        &__image {
                            justify-content: flex-end;
                            a {
                                margin-right: 48px;
                            }
                        }
                        &__link {
                            justify-content: flex-end;
                        }
                    }
                }
                &:last-of-type {
                    .component {
                        &__image {
                            justify-content: flex-start;
                            a {
                                margin-left: 48px;
                            }
                        }
                        &__link {
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }